import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";

@Injectable({
  providedIn: "root",
})
export class RegionsService {
  allCountries = [];
  constructor(private http: HttpClientService) {}

  getAllCities(ICityData) {
    return this.http.get("settings/countries/cities", ICityData);
  }

  getAllCountries() {
    return this.http.get("settings/countries", { return_all: 1 });
  }

  getCitiesByCountryID(countryId: number) {
    return this.http.get(`settings/countries/${countryId}/states`);
  }
}
