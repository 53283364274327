import { Injectable } from "@angular/core";
import { urlSettings } from "../../settings/urlSettings";
import { io, Socket } from "socket.io-client";
import { Observable } from "rxjs";
import { AuthenticationService } from "../auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class SocketService {
  private socketURL = "";
  private socket: Socket;

  constructor(private authService: AuthenticationService) {
    this.socketURL = `${urlSettings.Settings.apiProtocol}://${urlSettings.Settings.apiHost}:2112?user_id=${authService.User.id}`;
    // this.socketURL = `https://ef1c-197-58-255-150.ngrok-free.app/?user_id=${authService.User.id}`;
  }

  connect(): void {
    this.socket = io(this.socketURL);
    this.setUserData();
  }

  disconnect(): void {
    this.socket.disconnect();
  }

  emit(eventName: string, data?: any): void {
    this.socket.emit(eventName, data);
  }

  on(eventName: string): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on(eventName, (data: any) => {
        observer.next(data);
      });
    });
  }

  setUserData() {
    this.emit("set-user-data", {
      user_id: this.authService.User?.id,
      token: this.authService.User?.firebase_token,
      device_type: "web",
    });
  }
}
