import { Injectable } from "@angular/core";
import { socketNetwork } from "../services/socket-network-layer/sokcet-network.service";
import { HttpClientService } from "../services/http/http.service";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  constructor(
    private socketHttp: socketNetwork,
    private appHttp: HttpClientService
  ) {}
  getMessagesCount(userId) {
    return this.socketHttp.get("rooms/total-unread-messages", userId);
  }
  getReservationsCount() {
    return this.appHttp.get("reservations/status-count");
  }
}
