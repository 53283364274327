import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class  LoaderService {
  private loadingSubject = new BehaviorSubject<any>(false);
  public loading$ = this.loadingSubject.asObservable();

  show(): void {
    debugger
    this.loadingSubject.next(true);
    console.log(this.loadingSubject)
  }

  hide(): void {
    this.loadingSubject.next(false);
  }
}
