<div class="home-btn d-none d-sm-block">
  <a routerLink="/" class="text-white"><i class="fas fa-home h2"></i></a>
</div>

<div class="my-5 pt-sm-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <div class="row justify-content-center mt-5">
            <div class="col-sm-4">
              <div class="maintenance-img">
                <img
                  src="assets/images/Logo.png"
                  alt=""
                  class="img-fluid mx-auto d-block"
                />
              </div>
            </div>
          </div>
          <h4 class="mt-5">Let's get started with Rush Rooms</h4>
          <p class="text-muted">It will be as simple</p>

          <div class="row justify-content-center mt-5">
            <div class="col-md-8">
              <div
                inputDate="December 31, 2021"
                class="counter-number ico-countdown"
              >
                <div class="coming-box">{{ _days }}<span>Days</span></div>
                <div class="coming-box">{{ _hours }}<span>Hours</span></div>
                <div class="coming-box">{{ _minutes }}<span>Minutes</span></div>
                <div class="coming-box">{{ _seconds }}<span>Seconds</span></div>
              </div>
            </div>
            <!-- end col-->
          </div>
          <!-- end row-->
        </div>
      </div>
    </div>
  </div>
</div>
