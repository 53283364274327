import { Component, OnInit } from "@angular/core";
import { CachingService } from "src/app/core/services/caching/caching.service";
import { LanguageService } from "src/app/core/services/language/language.service";

@Component({
  selector: "app-language",
  templateUrl: "./language.component.html",
  styleUrls: ["./language.component.scss"],
})
export class LanguageComponent implements OnInit {
  showLang: boolean = false;
  element;
  savedLanguage;
  flagvalue;
  countryName;
  valueset;

  listLang;
  currentLang;

  constructor(
    private languageService: LanguageService,
    public cachingService: CachingService
  ) {}
  ngOnInit(): void {
    this.languageService.showLanguagesList.subscribe(
      (show) => (this.showLang = show)
    );

    this.listLang = this.languageService.listLang;
    this.setAppLanguage();
  }

  setAppLanguage() {
    this.element = document.documentElement;

    this.savedLanguage = this.cachingService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.savedLanguage);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.currentLang = val[0];
      this.flagvalue = val.map((element) => element.flag);
    }

    this.cachingService.set("lang", this.savedLanguage);

    if (this.savedLanguage === "en") {
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
  }

  setLanguage(language) {
    if (language) {
      this.countryName = language.text;
      this.flagvalue = language.flag;
      this.savedLanguage = language.lang;
      this.languageService.setLanguage(language.lang);

      if (language.lang === "en") {
        document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
        document.getElementsByTagName("html")[0].setAttribute("lang", "en");
        document.body.classList.add("en");
        document.body.classList.remove("ar");
      } else {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
        document.body.classList.add("ar");
        document.body.classList.remove("en");
      }
    }
    this.showLang = false;
  }
}
