import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ExtrapagesRoutingModule } from "./extrapages-routing.module";

import { Page404Component } from "./page404/page404.component";
import { Page500Component } from "./page500/page500.component";
import { ComingsoonComponent } from "./comingsoon/comingsoon.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    Page404Component,
    Page500Component,
    ComingsoonComponent,
    AboutUsComponent,
  ],
  imports: [CommonModule, TranslateModule, ExtrapagesRoutingModule],
})
export class ExtrapagesModule {}
