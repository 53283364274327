<div id="preloader" *ngIf="loading" dir="ltr">
  <div class="loading-container">
    <div class="logo"></div>
    <div class="dot-container">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
    <p>Loading</p>
  </div>
</div>
