import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private http: HttpClientService) {}

  public getAllNotificationsPaginate(page?: number) {
    return this.http.get("notifications", { page });
  }

  public markAsRead(notificationId: string) {
    return this.http.post({ id: notificationId }, "notifications/mark-as-read");
  }

  public markAllAsRead() {
    return this.http.post({}, "notifications");
  }

  public totalUnread() {
    return this.http.get("notifications/total-unread-notifications");
  }
}
