import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/messaging";

@Injectable({
  providedIn: "root",
})
export class FirebaseMessagesService {
  currentMessage = new BehaviorSubject(null);
  firebaseToken: string;

  constructor(private angularFireMessaging: AngularFireMessaging) {
    this.angularFireMessaging.onMessage((_messaging) => {
      this.currentMessage.next(_messaging);
    });
    this.receiveMessage();
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.firebaseToken = token;
      },
      (err) => {
        console.error("Unable to get permission to notify.", err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload) => {
      this.currentMessage.next(payload);
    });
  }
}
