import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { Page404Component } from "./page404/page404.component";
import { Page500Component } from "./page500/page500.component";
import { ComingsoonComponent } from "./comingsoon/comingsoon.component";
import { AboutUsComponent } from "./about-us/about-us.component";

const routes: Routes = [
  {
    path: "coming-soon",
    component: ComingsoonComponent,
  },
  {
    path: "404",
    component: Page404Component,
  },
  {
    path: "500",
    component: Page500Component,
  },
  {
    path: "aboutus",
    component: AboutUsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExtrapagesRoutingModule {}
