import { Injectable } from "@angular/core";
import { HttpClientService } from "../http/http.service";
import { IpropertyData } from "../../models/property.model";
import { BehaviorSubject, Observable } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class PropertiesService {
  _Options: IpropertyData = {
    return_all: 0,
    page: 1,
  };
  private propertiesSubject : BehaviorSubject<any>= new BehaviorSubject<any>([]); // Step 1: Create a BehaviorSubject
  public properties:Observable<any[]> = this.propertiesSubject.asObservable();
  public propDetailsLoaded = new BehaviorSubject<any>(null);
  public pricesDetailsLoaded = new BehaviorSubject<any>(null);

  // properties = [];

  constructor(private http: HttpClientService) {
    this.getAllPropertiesData()
  }

  getAllProperties(options: IpropertyData) {
    return this.http.get("properties", options);
  }

  getPropertyById(id: number) {
    return this.http.get(`properties/${id}`);
  }

  createProperty(body) {
    return this.http.postFormData(body, "properties/create");
  }
  updateProperty(body, id) {
    return this.http.postFormData(body, `properties/${id}/edit`);
  }
  deleteProperty(id) {
    return this.http.post({}, `properties/${id}/delete`);
  }

  deletePropertyMedia(id) {
    return this.http.post({}, `properties/${id}/media-delete`);
  }

  setPropertyPrice(body, id) {
    return this.http.postFormData(body, `properties/${id}/set-prices`);
  }
  updatePropertyPrice(body, id) {
    return this.http.post(body, `properties/${id}/edit-prices`);
  }

  getAllPropertyRates(id: number) {
    return this.http.get(`properties/${id}/calendar`, { default: 1 });
  }
  setPropertyDefaultPriceRate(propID, rateId) {
    return this.http.post(
      {},
      `properties/${propID}/${rateId}/set-search-default`
    );
  }

  getProrepertyCalenderDetails(id) {
    return this.http.get(`properties/${id}/all`);
  }

  disableReservation(propID, body) {
    return this.http.post(body, `properties/${propID}/disabled`);
  }

  enableReservation(propID, body) {
    return this.http.post(body, `properties/${propID}/remove-disabled`);
  }

  uploadPropertyGaleryImages(body) {
    return this.http.postFormData(body, "properties/upload-media").toPromise();
  }

  getPropertyCancelationTerms(id: number) {
    return this.http.get(`settings/pages/${id}`);
  }
  getAllPropertiesData() {
    this
      .getAllProperties(this._Options)
      .pipe(take(1))
      .subscribe((res) => {
        debugger
        const properties = res.data ;
        this.propertiesSubject.next(properties);
        // this.currentpage = res.data.current_page;
        // this.perPageSize = res.data.per_page;
        // this.totalPageSize = res.data.total;
      });
  }
}
