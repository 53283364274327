import { Component, OnInit } from "@angular/core";
import { LoaderService } from "src/app/core/services/loader/loader.service";

@Component({
  selector: "app-loader",
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit {
  loading: boolean = false;

  constructor(private loaderService: LoaderService) {
    debugger
  }
  ngOnInit(): void {
    debugger
    this.loaderService.loading$.subscribe((load) => {
      this.loading = load;
    });
  }
}
