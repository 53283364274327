import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbTimepickerModule,
  NgbDropdownModule,
  NgbAlertModule,
} from "@ng-bootstrap/ng-bootstrap";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { RouterModule } from "@angular/router";
import { CutStringsPipe } from "src/app/core/pipes/cut-strings.pipe";
import { CurrencyPipe } from "src/app/core/pipes/currency.pipe";
@NgModule({
  declarations: [PagetitleComponent, CutStringsPipe, CurrencyPipe],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbDropdownModule,
    NgbAlertModule,
  ],
  exports: [PagetitleComponent, CutStringsPipe, CurrencyPipe],
})
export class UIModule {}
